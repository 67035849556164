.App {
  text-align: center;
}

.App-logo {
  height: auto;
  width: 20vw;
  pointer-events: none;
}

.App-header {
  background-color: #0E1F30;
  min-height: 100vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

h1 {
  color: #F2E0B3;
  font-size: 56px;
  font-family: "Kanit", serif;

}

h2 {
  color: white;
  font-family: "roboto";
}

.App-link {
  color: #61dafb;
}

